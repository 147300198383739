// Imports
// ------
import { css } from 'styled-components';
import { breakpointUp } from './breakpoints';

// Exports
// --------------
// 1. Shared Heading Styles
// --------------
const sharedHeadingStyles = (props) => css`
	font-weight: ${props.isAlt ? props.theme.semi : props.theme.reg};
	font-family: ${props.theme.heading};
	font-style: ${props.isAlt ? `italic` : `normal`};
	line-height: 120%;
	color: ${props.isLight ? props.theme.bc2 : props.theme.bc1};

	> em {
		font-style: italic;
		font-weight: ${props.theme.semi};
	}
`;

// --------------
// 2. Heading Styles
// --------------
export const h1Styles = css`
	${sharedHeadingStyles}
	font-size: 4rem;

	${breakpointUp.medium` font-size: 7.2rem; `}
	${breakpointUp.xlarge` font-size: 9.6rem; `}
`;

export const h2Styles = css`
	${sharedHeadingStyles}
	font-size: 3.6rem;

	${breakpointUp.medium` font-size: 4.8rem; `}
	${breakpointUp.xlarge` font-size: 6rem; `}
`;

export const h3Styles = css`
	${sharedHeadingStyles}
	font-size: 3.2rem;

	${breakpointUp.medium` font-size: 4.2rem; `}
	${breakpointUp.xlarge` font-size: 4.6rem; `}
`;

export const h4Styles = css`
	${sharedHeadingStyles}
	font-size: 2.6rem;

	${breakpointUp.medium` font-size: 3.2rem; `}
	${breakpointUp.xlarge` font-size: 3.6rem; `}
`;

export const h5Styles = css`
	${sharedHeadingStyles}
	font-size: 2rem;

	${breakpointUp.medium` font-size: 2.2rem; `}
	${breakpointUp.large` font-size: 2.4rem; `}
`;

// --------------
// 3. Shared Body Styles
// --------------
const sharedBodyStyles = (props) => css`
	font-family: ${props.isAlt ? props.theme.heading : props.theme.body};
	font-weight: ${props.isAlt ? props.theme.semi : props.theme.reg};
	font-style: ${props.isAlt ? `italic` : `normal`};
	font-size: ${props.small ? 1.2 : 1.4}rem;
	line-height: 140%;
	color: ${props.isLight ? props.theme.bc2 : props.theme.bc1};

	> em {
		font-style: italic;
		font-weight: ${props.theme.semi};
	}

	${breakpointUp.xlarge`
        font-size: ${props.small ? 1.4 : 1.6}rem;
    `}
`;

// --------------
// 3. Body Styles
// --------------
export const pStyles = (props) => css`
	${sharedBodyStyles}
`;

export const spanStyles = (props) => css`
	${sharedBodyStyles}
`;

export const emStyles = (props) => css`
	${sharedBodyStyles}
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.2rem;
`;
