// Imports
// ------
import React from 'react';
import Icon from '@parts/Icons';

// Styles
// ------
import { Jacket, Text, Button } from './styles';

// Component
// ------
const Cookies = ({ onClick, isScrolling }) => (
	<Jacket isScrolling={isScrolling}>
		<Text small>
			The use of cookies are necessary for the functioning of our website.
			By using our site you are accepting our cookies. To learn more
			please read our Privacy Policy
		</Text>
		<Button onClick={onClick}>
			<Icon type='close' />
		</Button>
	</Jacket>
);

export default Cookies;
