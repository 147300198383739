module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/src/layouts/index.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NLXFS39","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"small":"(max-width: 370px)","smedium":"(max-width: 768px)","medium":"(max-width: 1024px)","large":"(max-width: 1200px)","xlarge":"(max-width: 1440px)","xxlarge":"(max-width: 1600px)","huge":"(max-width: 2000px)","uber":"(max-width: none)","portrait":"(orientation: portrait)","horizontal":"(orientation: horizontal)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fountain","short_name":"Fountain","start_url":"/","background_color":"#121212","theme_color":"#D8CCC2","display":"minimal-ui","icon":"src/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"93651250faaf71e17572c54ee20a051e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
