// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, P } from '@tackl';

// Exports
// ------
export const Jacket = styled.div(
	(props) => css`
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		padding: 1.2rem 1.2rem 1.2rem 2.4rem;

		background: ${props.theme.bc2};
		transition: all 0.25s ease-in-out;

		${props.isScrolling &&
		css`
			transform: translateY(-100%);
		`}

		${breakup.large`
            padding: 1.2rem 4.8rem;
        `}
	`
);

export const Text = styled(P)((props) => css``);

export const Button = styled.button(
	(props) => css`
		background: transparent;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		margin-left: 1.2rem;

		svg {
			fill: ${props.theme.bc1};
			width: 2.4rem;
			height: 2.4rem;
			transition: all 0.25s ease-in-out;
		}

		&:hover svg {
			fill: ${props.theme.bc3};
		}
	`
);
