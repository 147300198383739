// Imports
// ------
import React, { useEffect, useRef, useState } from 'react';
import Logo from '@parts/Logo';
import Button from '@parts/Button';
import Headroom from 'react-headroom';
import Cookies from './Cookies';
import { headerSize } from '@states/headerSize';
import { action } from 'mobx';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
// import { headerState } from '@states/header';
import { observer } from 'mobx-react';
import { StaticQuery, graphql } from 'gatsby';

// Styles
// ------
import { Jacket, Content, BackHome, Info, Desc, Contact } from './styles';

// Component
// ------
const Header = ({ cookiesAccepted, onClick }) => {
	const header = useRef();
	const bp = useBreakpoint();

	const [top, setTop] = useState(true);
	const [scrollingDown, setScrollingDown] = useState(false);
	// const [noBg, setNoBg] = useState(false);

	const handleTop = () => {
		setTop(true);
		setScrollingDown(false);
	};

	const handleScrollDown = () => {
		setTop(false);
		setScrollingDown(true);
	};

	const handleScrollUp = () => {
		setTop(false);
		setScrollingDown(false);
	};

	useEffect(
		action(() => {
			headerSize.height = header.current.offsetHeight;
		})
	);

	// useEffect(() => {
	// 	setNoBg(headerState.noBg);
	// }, [headerState.noBg]);

	return (
		<StaticQuery
			query={graphql`
				query HeadingQuery {
					global: datoCmsGlobal {
						desc: fountainDescription
					}
				}
			`}
			render={(data) => (
				<Jacket isClickable={!scrollingDown}>
					<Headroom
						disableInlineStyles
						onUnfix={handleTop}
						onPin={handleScrollUp}
						onUnpin={handleScrollDown}
					>
						<Content
							ref={header}
							atTop={top}
							isScrolling={scrollingDown}
							// noBg={noBg}
						>
							<Info>
								<BackHome to='/'>
									<Logo />
								</BackHome>
								{bp.large ? null : (
									<Desc small>{data.global.desc}</Desc>
								)}
							</Info>

							<Contact>
								<Button isLight to='/contact/'>
									Contact
								</Button>
							</Contact>
						</Content>
						{!cookiesAccepted && (
							<Cookies
								onClick={onClick}
								isScrolling={scrollingDown}
							/>
						)}
					</Headroom>
				</Jacket>
			)}
		/>
	);
};

export default observer(Header);
