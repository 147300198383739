// Imports
// ------
import { css } from 'styled-components';
import { containerStyles } from './containerStyles';

// Exports
// ------
export const listStyles = css`
	${containerStyles}
	list-style: none;
`;

export const listItemStyles = css`
	display: block;

	> a {
		display: block;
	}
`;
