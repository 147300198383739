// Imports
// ------
import React from 'react';
import Icon from '@icons';

// Styles
// ------
import { JacketExt, JacketInt, JacketButton, Sweater } from './styles';

// Component
// ------
const Button = ({
	className,
	isExternal,
	to,
	hasIcon,
	children,
	isLight,
	submit,
	disabled,
}) => {
	if (submit)
		return (
			<JacketButton
				className={className}
				type='submit'
				disabled={disabled}
			>
				<Sweater isLight={isLight}>
					{children}
					{hasIcon && <Icon type={hasIcon} />}
				</Sweater>
			</JacketButton>
		);

	if (isExternal)
		return (
			<JacketExt
				className={className}
				href={to}
				target='_blank'
				rel='noopener noreferrer'
			>
				<Sweater isLight={isLight}>
					{children}
					{hasIcon && <Icon type={hasIcon} />}
				</Sweater>
			</JacketExt>
		);

	if (!isExternal)
		return (
			<JacketInt className={className} to={to}>
				<Sweater isLight={isLight}>
					{children}
					{hasIcon && <Icon type={hasIcon} />}
				</Sweater>
			</JacketInt>
		);

	return null;
};

export default React.memo(Button);
