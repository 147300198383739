// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';

// Exports
// ------
export const Jacket = styled.svg(props => css`
    width: 12rem;
    height: 1rem;

    ${breakup.medium`
        width: 20.4rem;
        height: 1.6rem;
    `}
`);
