// Imports
// ------
import React from 'react';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const Icon = ({ type, className, onClick }) => {
	if (type === 'facebook')
		return (
			<Jacket
				height='24'
				width='24'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}
			>
				<path d='M24,12.072A12,12,0,1,0,10.125,23.926V15.541H7.078V12.072h3.047V9.428c0-3.007,1.792-4.669,4.532-4.669a18.611,18.611,0,0,1,2.687.234V7.947H15.83a1.734,1.734,0,0,0-1.947,1.49,1.71,1.71,0,0,0-.008.385v2.25H17.2l-.532,3.469h-2.8v8.385A12,12,0,0,0,24,12.072Z' />
			</Jacket>
		);

	if (type === 'codepen')
		return (
			<Jacket
				height='24'
				width='24'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}
			>
				<path d='M24,8.2C24,8.2,24,8.2,24,8.2c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1 c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l-11-7.3 c-0.3-0.2-0.8-0.2-1.1,0l-11,7.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1 c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1v7.3 c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1 c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l11,7.3c0.2,0.1,0.4,0.2,0.6,0.2 c0.2,0,0.4-0.1,0.6-0.2l11-7.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1 c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1V8.3 C24,8.3,24,8.3,24,8.2z M13,3l8.1,5.4l-3.6,2.4l-4.5-3V3z M11,3v4.8l-4.5,3L2.9,8.3L11,3z M2.1,10.3L4.6,12l-2.6,1.7V10.3z M11,21 l-8.1-5.4l3.6-2.4l4.5,3V21z M12,14.4L8.4,12L12,9.6l3.6,2.4L12,14.4z M13,21v-4.8l4.5-3l3.6,2.4L13,21z M21.9,13.7L19.4,12l2.6-1.7 V13.7z' />
			</Jacket>
		);

	if (type === 'dribbble')
		return (
			<Jacket
				height='24'
				width='24'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M22.1,13.6c-0.4-0.1-3.2-1-6.4-0.4c1.3,3.7,1.9,6.7,2,7.3C20,19,21.7,16.5,22.1,13.6z M16,21.5c-0.2-0.9-0.7-4-2.2-7.8 c0,0,0,0-0.1,0c-5.8,2-7.9,6-8,6.4c1.7,1.4,3.9,2.2,6.3,2.2C13.4,22.3,14.8,22,16,21.5z M4.4,18.9c0.2-0.4,3-5.1,8.3-6.8 c0.1,0,0.3-0.1,0.4-0.1c-0.3-0.6-0.5-1.2-0.8-1.7c-5.1,1.5-10.1,1.5-10.5,1.5c0,0.1,0,0.2,0,0.3C1.8,14.6,2.7,17.1,4.4,18.9z M2,9.9 c0.5,0,4.7,0,9.5-1.2c-1.7-3-3.5-5.6-3.8-5.9C4.8,4.1,2.6,6.7,2,9.9z M9.6,2.1c0.3,0.4,2.1,2.9,3.8,6c3.6-1.4,5.2-3.4,5.4-3.7 C17,2.7,14.6,1.8,12,1.8C11.2,1.8,10.4,1.9,9.6,2.1z M19.9,5.5c-0.2,0.3-1.9,2.5-5.7,4c0.2,0.5,0.5,1,0.7,1.5 c0.1,0.2,0.1,0.4,0.2,0.5c3.4-0.4,6.8,0.3,7.1,0.3C22.2,9.5,21.4,7.3,19.9,5.5z'
				/>
			</Jacket>
		);

	if (type === 'linkedin')
		return (
			<Jacket
				height='24'
				width='24'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}
			>
				<path d='M23,0H1C0.4,0,0,0.4,0,1v22c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1V1C24,0.4,23.6,0,23,0z M7.1,20.5H3.6V9h3.6 V20.5z M5.3,7.4c-1.1,0-2.1-0.9-2.1-2.1c0-1.1,0.9-2.1,2.1-2.1c1.1,0,2.1,0.9,2.1,2.1C7.4,6.5,6.5,7.4,5.3,7.4z M20.5,20.5h-3.6 v-5.6c0-1.3,0-3-1.8-3c-1.9,0-2.1,1.4-2.1,2.9v5.7H9.4V9h3.4v1.6h0c0.5-0.9,1.6-1.8,3.4-1.8c3.6,0,4.3,2.4,4.3,5.5V20.5z' />
			</Jacket>
		);

	if (type === 'arrow right')
		return (
			<Jacket
				width='16'
				height='16'
				viewBox='0 0 16 16'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}
			>
				<path d='M0 8.5C0 8.22386 0.223858 8 0.5 8H15.5C15.7761 8 16 8.22386 16 8.5C16 8.77614 15.7761 9 15.5 9H0.5C0.223858 9 0 8.77614 0 8.5Z' />
				<path d='M10.1464 3.14645C10.3417 2.95118 10.6583 2.95118 10.8536 3.14645L15.8536 8.14645C16.0488 8.34171 16.0488 8.65829 15.8536 8.85355L10.8536 13.8536C10.6583 14.0488 10.3417 14.0488 10.1464 13.8536C9.95118 13.6583 9.95118 13.3417 10.1464 13.1464L14.7929 8.5L10.1464 3.85355C9.95118 3.65829 9.95118 3.34171 10.1464 3.14645Z' />
			</Jacket>
		);

	if (type === 'close')
		return (
			<Jacket
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}
			>
				<path d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z' />
			</Jacket>
		);

	if (type === 'lock')
		return (
			<Jacket
				width='49'
				height='56'
				viewBox='0 0 49 56'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}
			>
				<path
					d='M48.4688 26.0938H1.34375V55.0938H48.4688V26.0938Z'
					strokeMiterlimit='10'
					strokeLinecap='square'
				/>
				<path
					d='M12.2188 26.0938V13.4062C12.2188 10.0413 13.5555 6.8142 15.9348 4.43483C18.3142 2.05546 21.5413 0.71875 24.9062 0.71875C28.2712 0.71875 31.4983 2.05546 33.8777 4.43483C36.257 6.8142 37.5938 10.0413 37.5938 13.4062V26.0938'
					strokeMiterlimit='16'
					strokeLinecap='square'
				/>
				<path
					d='M24.9062 46.0312C27.9093 46.0312 30.3438 43.5968 30.3438 40.5938C30.3438 37.5907 27.9093 35.1562 24.9062 35.1562C21.9032 35.1562 19.4688 37.5907 19.4688 40.5938C19.4688 43.5968 21.9032 46.0312 24.9062 46.0312Z'
					strokeMiterlimit='10'
					strokeLinecap='square'
				/>
			</Jacket>
		);

	if (type === 'unlock')
		return (
			<Jacket
				width='49'
				height='56'
				viewBox='0 0 49 56'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}
			>
				<path
					d='M48.125 26.375H1V55.375H48.125V26.375Z'
					strokeMiterlimit='10'
					strokeLinecap='square'
				/>
				<path
					d='M11.875 13.6875C11.875 10.3226 13.2117 7.09545 15.5911 4.71608C17.9705 2.33671 21.1976 1 24.5625 1C27.9274 1 31.1545 2.33671 33.5339 4.71608C35.9133 7.09545 37.25 10.3226 37.25 13.6875V26.375'
					strokeMiterlimit='16'
					strokeLinecap='square'
				/>
				<path
					d='M24.5625 46.3125C27.5655 46.3125 30 43.878 30 40.875C30 37.872 27.5655 35.4375 24.5625 35.4375C21.5595 35.4375 19.125 37.872 19.125 40.875C19.125 43.878 21.5595 46.3125 24.5625 46.3125Z'
					strokeMiterlimit='10'
					strokeLinecap='square'
				/>
			</Jacket>
		);

	console.error(
		`You've not added the correct 'type' prop to your <Icon /> component`
	);
	console.warn(
		`Remember to add "type='YOUR_ICON_NAME'" to your <Icon /> props or else your icon wont display`
	);
	return null;
};

export default React.memo(Icon);
