// Imports
// ------
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from '@parts/Header';
import { useCookies, CookiesProvider } from 'react-cookie';

// Styles
// ------
import '@styles/global.css';
import { ThemeProvider } from 'styled-components';
import { lightTheme, GlobalStyle } from '@theme';

// Component
// ------
const Layout = ({ children }) => {
	const [cookies, setCookie] = useCookies(['policy']);

	function handleCookie() {
		setCookie('policy', true, {
			path: '/',
		});
	}

	useEffect(() => {
		const setHeightVar = () => {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		};
		setHeightVar();
	}, []);

	return (
		<CookiesProvider>
			<ThemeProvider theme={lightTheme}>
				<GlobalStyle theme={lightTheme} />
				<Header
					cookiesAccepted={cookies.policy}
					onClick={handleCookie}
				/>
				{children}
			</ThemeProvider>
		</CookiesProvider>
	);
};

export default Layout;

Layout.defaultProps = {
	children: null,
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};
