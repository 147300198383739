// Imports
// ------
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { breakup } from '@tackl';
import { pStyles } from '@type';

// Exports
// ------
export const Sweater = styled.span(
	(props) => css`
		${pStyles}
		display: inline-flex;
		align-items: center;
		justify-content: flex-start;
		transition: all 0.25s ease-in-out;

		padding: 1rem 2.4rem;
		text-transform: uppercase;
		border-radius: 2.4rem;
		background: transparent;
		border: 1px solid ${props.isLight ? props.theme.bc2 : props.theme.bc1};
		color: ${props.isLight ? props.theme.bc2 : props.theme.bc1};

		&:hover {
			background: ${props.isLight ? props.theme.bc2 : props.theme.bc1};
			border: 1px solid
				${props.isLight ? props.theme.bc2 : props.theme.bc1};
			color: ${props.isLight ? props.theme.bc1 : props.theme.bc2};

			svg {
				fill: ${props.isLight ? props.theme.bc1 : props.theme.bc2};
			}
		}

		svg {
			margin-left: 1.2rem;
			width: 1.6rem;
			height: 1.6rem;
			fill: ${props.isLight ? props.theme.bc2 : props.theme.bc1};
			transition: all 0.25s ease-in-out;
		}
	`
);

const sharedStyles = css`
	display: inline-block;
	background: transparent;

	&:hover {
		cursor: pointer;
	}
`;

export const JacketInt = styled(Link)`
	${sharedStyles}
`;

export const JacketExt = styled.a`
	${sharedStyles}
`;

export const JacketButton = styled.button`
	${sharedStyles}
	padding: 0;
	text-align: left;
`;

export const JacketSecondaryInt = styled(Link)``;

export const JacketSecondaryExt = styled.a``;

export const SweaterSecondary = styled.span(
	(props) => css`
		${pStyles}
		position: relative;
		display: inline-flex;
		align-items: center;
		justify-content: flex-start;
		transition: all 0.25s ease-in-out;
		color: ${props.isLight ? props.theme.bc2 : props.theme.bc1};

		border-radius: 2.4rem;
		border: 1px solid ${props.theme.bc2};
		padding: 0.6rem 1.2rem;
		margin-left: 1.6rem;

		${breakup.large`
            border: 1px solid transparent;
        `}

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

		&:before {
			left: -1.6rem;
			width: 4px;
			height: 4px;
			border: 1px solid ${props.theme.bc2};
			border-radius: 4px;
		}

		&:after {
			left: -1.1rem;
			height: 1px;
			width: 1.1rem;
			background: ${props.theme.bc2};
			transition: all 0.25s ease-in-out;
			transform-origin: center left;

			${breakup.large` transform: scaleX(0); `}
		}

		&:hover {
			${breakup.large`
                border: 1px solid ${props.theme.bc2};
                border-radius: 2.4rem;
                cursor: pointer;
                transition-delay: 0.15s;

                &:after {
                    transform: scaleX(1);
                }
            `}
		}
	`
);
