// Imports
// ---------
import styled, { css } from 'styled-components';
import { containerStyles } from './containerStyles';
import { imageStyles } from './imageStyles';
import {
	h1Styles,
	h2Styles,
	h3Styles,
	h4Styles,
	h5Styles,
	pStyles,
	spanStyles,
	emStyles,
} from './typographyStyles';
import { listStyles, listItemStyles } from './listStyles';
import { breakpointUp, breakpointDown, breakpointOnly } from './breakpoints';

// --------------
// 1. Containers
// --------------
export const Section = styled.section`
	${containerStyles}
`;
export const Div = styled.div`
	${containerStyles}
`;
export const Main = styled.main`
	${containerStyles}
`;
export const Nav = styled.nav`
	${containerStyles}
`;
export const Article = styled.article`
	${containerStyles}
`;
export const Header = styled.header`
	${containerStyles}
`;
export const Footer = styled.footer`
	${containerStyles}
`;

// --------------
// 2. Images
// --------------
export const Img = styled.img`
	${imageStyles}
`;

// --------------
// 3. Typography
// --------------
export const H1 = styled.h1`
	${h1Styles}
`;
export const H2 = styled.h2`
	${h2Styles}
`;
export const H3 = styled.h3`
	${h3Styles}
`;
export const H4 = styled.h4`
	${h4Styles}
`;
export const H5 = styled.h5`
	${h5Styles}
`;
export const P = styled.p`
	${pStyles}
`;
export const Em = styled.em`
	${emStyles}
`;
export const Span = styled.span`
	${spanStyles}
`;

// --------------
// 4. List
// --------------
export const List = styled.ul`
	${listStyles}
`;
export const ListItem = styled.li`
	${listItemStyles}
`;

// --------------
// 4. Breakpoints
// --------------
export const breakup = breakpointUp;
export const breakdown = breakpointDown;
export const breakonly = breakpointOnly;

// --------------
// 4. Content Block Styling
// --------------
export const ContentBlock = styled(Div)(
	(props) => css`
		> * {
			margin-bottom: 2.4rem;

			${breakpointUp.large` margin-bottom: 3.6rem; `}

			&:last-child {
				margin-bottom: 0;
			}
		}

		h1 {
			${h1Styles}
		}

		h2 {
			${h2Styles}
		}

		h3 {
			${h3Styles}
		}

		h4 {
			${h4Styles}
		}

		h5 {
			${h5Styles}
		}

		p {
			${pStyles}
			strong {
				font-weight: ${props.theme.bold};
			}
		}

		ul li,
		ol li {
			${pStyles}
			position: relative;
			margin: 0 0 1.2rem;

			${breakpointUp.large` margin: 0 0 2.4rem; `}

			&:last-child {
				margin: 0;
			}

			&:before {
				display: inline;
			}
		}

		ul {
			li {
				&:before {
					content: '• ';
				}
			}
		}

		ol {
			list-style: none;
			counter-reset: my-awesome-counter;

			li {
				counter-increment: my-awesome-counter;

				&:before {
					content: counter(my-awesome-counter) '. ';
				}
			}
		}

		img {
			width: 100%;
			height: auto;
		}

		a {
			${pStyles}
			position: relative;
			display: inline-block;
			transition: all 0.25s ease-in-out;

			&:before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 1px;
				background: ${props.theme.bc1};
				transition: all 0.25s ease-in-out;
			}

			&:hover {
				color: ${props.theme.bc3};

				&:before {
					background: ${props.theme.bc3};
					transform: scaleX(0);
				}
			}
		}
	`
);
