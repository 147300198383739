// Imports
// ------
import { css } from 'styled-components';
import { breakpointUp, breakpointDown } from './breakpoints';

// Exports
// --------------
// 1. Margin
// --------------
const marginStyles = (props) => css`
	${props.marBottom &&
	css`
		margin-bottom: ${props.theme.padS};

		${breakpointUp.medium` margin-bottom: ${props.theme.padM}; `}
		${breakpointUp.large` margin-bottom: ${props.theme.padL}; `}
	`}

	${props.marTop &&
	css`
		margin-top: ${props.theme.padS};

		${breakpointUp.medium` margin-top: ${props.theme.padM}; `}
		${breakpointUp.large` margin-top: ${props.theme.padL}; `}
	`}
    
    ${props.mar &&
	css`
		margin-top: ${props.theme.padS};
		margin-bottom: ${props.theme.padS};

		${breakpointUp.medium`
            margin-top: ${props.theme.padM};
            margin-bottom: ${props.theme.padM};
        `}

		${breakpointUp.large`
            margin-top: ${props.theme.padL};
            margin-bottom: ${props.theme.padL};
        `}
	`}
`;

// --------------
// 2. Padding
// --------------
const paddingStyles = (props) => css`
	${props.mpad &&
	css`
		padding-left: ${props.theme.mpad};
		padding-right: ${props.theme.mpad};

		${breakpointUp.large`
            padding-left: 0px;
            padding-right: 0px;
        `}
	`}

	${props.mpadLarge &&
	css`
		padding-left: ${props.theme.mpad};
		padding-right: ${props.theme.mpad};
	`}

    ${props.padBottom &&
	css`
		padding-bottom: ${props.theme.padS};

		${breakpointUp.medium` padding-bottom: ${props.theme.padM}; `}
		${breakpointUp.large` padding-bottom: ${props.theme.padL}; `}
	`}

    ${props.padTop &&
	css`
		padding-top: ${props.theme.padS};

		${breakpointUp.medium` padding-top: ${props.theme.padM}; `}
		${breakpointUp.large` padding-top: ${props.theme.padL}; `}
	`}
    
    ${props.pad &&
	css`
		padding-top: ${props.theme.padS};
		padding-bottom: ${props.theme.padS};

		${breakpointUp.medium`
            padding-top: ${props.theme.padM};
            padding-bottom: ${props.theme.padM};
        `}

		${breakpointUp.large`
            padding-top: ${props.theme.padL};
            padding-bottom: ${props.theme.padL};
        `}
	`}
`;

// --------------
// 3. Vertical Alignment
// --------------
const verticalAlignmentStyles = (props) => css`
	${props.valign && props.theme.valign}
	${props.valignMedium && breakpointUp.medium` ${props.theme.valign} `}
    ${props.valignLarge && breakpointUp.large` ${props.theme.valign} `}
    ${props.valignSmallOnly && breakpointDown.smedium` ${props.theme.valign} `}
`;

// --------------
// 4. Background Colours
// --------------
const backgroundColorStyles = css`
	background-color: ${(props) => [
		props.bc1 && props.theme.bc1,
		props.bc2 && props.theme.bc2,
		props.bc3 && props.theme.bc3,
	]};
`;

// --------------
// 5. Fullscreen
// --------------
const fullscreenStyles = (props) => css`
	${props.fullscreen &&
	css`
		width: 100%;
		height: 100vh;
	`}
`;

// --------------
// 6. Text Alignment
// --------------
const textAlignmentStyles = (props) => css`
	${props.text === 'center' && ` text-align: center; `}
	${props.text === 'centerMedium' &&
	css`
		${breakpointUp.medium` text-align: center; `}
	`}
    ${props.text === 'centerLarge' &&
	css`
		${breakpointUp.large` text-align: center; `}
	`}

    ${props.text === 'left' && ` text-align: left; `}
    ${props.text === 'leftMedium' &&
	css`
		${breakpointUp.medium` text-align: left; `}
	`}
    ${props.text === 'leftLarge' &&
	css`
		${breakpointUp.large` text-align: left; `}
	`}

    ${props.text === 'right' && ` text-align: right; `}
    ${props.text === 'rightMedium' &&
	css`
		${breakpointUp.medium` text-align: right; `}
	`}
    ${props.text === 'rightLarge' &&
	css`
		${breakpointUp.large` text-align: right; `}
	`}
`;

// --------------
// 7. Export: Container Styles
// --------------
export const containerStyles = css`
	${verticalAlignmentStyles}
	${backgroundColorStyles}
    ${paddingStyles}
    ${marginStyles}
    ${fullscreenStyles}
    ${textAlignmentStyles}

	${(props) =>
		props.bg &&
		`
		position: relative;
		z-index: 1;
		background-color: ${props.theme.bc2};
	`}

	${(props) =>
		props.borderBottom &&
		`
		border-bottom: 1px solid ${props.theme.bc1};
	`}
	
	${(props) =>
		props.borderTop &&
		`
		border-top: 1px solid ${props.theme.bc1};
	`}
`;
