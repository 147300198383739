// Imports
// ------
import React from 'react';

// Styles
// ------
import {
    Jacket,
} from './styles';

// Component
// ------
const Logo = ({ className }) => (
    <Jacket width="204" height="16" viewBox="0 0 204 16" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M35.2706 0C28.566 0 25.6926 3.02232 25.6926 7.97768C25.6926 12.9554 28.566 16 35.2706 16C41.9533 16 44.8486 12.933 44.8486 7.97768C44.8486 3.04464 41.9533 0 35.2706 0ZM35.2706 13.7768C30.6347 13.7768 28.3036 12.3103 28.3036 7.97768C28.3036 3.66741 30.6106 2.22321 35.2706 2.22321C39.9306 2.22321 42.2376 3.68973 42.2376 7.97768C42.2354 12.2879 39.9065 13.7768 35.2706 13.7768Z"/>
        <path d="M72.2806 2.88843V7.97773C72.2806 10.5112 71.6924 13.7769 65.3136 13.7769C58.9349 13.7769 58.3467 10.5335 58.3467 7.97773V2.88843C58.3467 1.41521 57.1768 0.221008 55.7335 0.221008V7.9755C55.7335 13.3974 58.6069 15.9978 65.3115 15.9978C71.9941 15.9978 74.8894 13.3751 74.8894 7.9755V0.221008C73.4505 0.221008 72.2806 1.41521 72.2806 2.88843Z"/>
        <path d="M115.869 0.221008C115.869 1.44869 116.845 2.44199 118.045 2.44199H123.923V15.7746C125.366 15.7746 126.536 14.5804 126.536 13.1072V2.44422H132.414C133.617 2.44422 134.59 1.44869 134.59 0.22324L115.869 0.221008Z"/>
        <path d="M170.645 2.88842V15.7768C172.088 15.7768 173.258 14.5826 173.258 13.1094V0.221008C171.815 0.221008 170.645 1.41521 170.645 2.88842Z"/>
        <path d="M2.61316 3.52076L0 1.29755V15.7775C1.44325 15.7775 2.61316 14.5833 2.61316 13.11V10.3333H12.6263C13.829 10.3333 14.8021 9.33772 14.8021 8.11228H2.61316V3.52076Z"/>
        <path d="M1.86311 1.81081C2.34638 2.22376 2.95648 2.4492 3.58626 2.4492L13.9318 2.44697C15.1345 2.44697 16.1076 1.45143 16.1076 0.223755H0L1.86311 1.81081Z"/>
        <path d="M102.804 2.88842V12.0804L105.417 14.6474V0.221008C103.974 0.221008 102.804 1.41521 102.804 2.88842Z"/>
        <path d="M87.3799 0.221008H85.7879L100.089 14.2456C101.091 15.2277 102.427 15.7768 103.815 15.7768H105.403L91.1061 1.75226C90.1045 0.770116 88.7706 0.221008 87.3799 0.221008Z"/>
        <path d="M85.7796 15.7767C87.2229 15.7767 88.3928 14.5825 88.3928 13.1093V3.92402L85.7796 1.35706V15.7767Z"/>
        <path d="M184.362 15.7769C185.806 15.7769 186.976 14.5827 186.976 13.1094V3.93086L184.362 1.36389V15.7769Z"/>
        <path d="M201.387 2.88842V12.0871L204 14.6541V0.221008C202.555 0.221008 201.387 1.41521 201.387 2.88842Z"/>
        <path d="M185.963 0.221008H184.362L198.67 14.2456C199.672 15.2277 201.006 15.7746 202.394 15.7746H203.975L189.689 1.75449C188.688 0.770116 187.352 0.221008 185.963 0.221008Z"/>
        <path d="M156.77 9.1228L153.076 2.77458C152.158 1.19199 150.487 0.221008 148.685 0.221008L154.294 9.8661H145.934L149.645 3.47547L148.191 0.984401L139.599 15.7768H140.968C141.915 15.7768 142.792 15.2679 143.278 14.4375L144.651 12.0871H155.578L156.951 14.4375C157.437 15.2679 158.314 15.7768 159.26 15.7768H160.629L156.765 9.12503L156.77 9.1228Z"/>
    </Jacket>
);

export default Logo;
