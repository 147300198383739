// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, P } from '@tackl';
import { Link } from 'gatsby';

// Exports
// ------
export const Jacket = styled.header(
	(props) => css`
		position: fixed;
		z-index: 12;
		top: 0;
		left: 0;
		right: 0;

		.headroom {
			position: relative;
			width: 100%;
			transition: all 0.25s ease-in-out;
		}
		.headroom--unfixed {
			transform: translateY(0);
		}

		.headroom--unpinned {
			transform: translateY(-100%);
		}

		.headroom--pinned {
			transform: translateY(0%);
		}

		pointer-events: ${props.isClickable ? 'auto' : 'none'};
	`
);

export const BackHome = styled(Link)`
	display: inline-block;
`;

export const Content = styled.div(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: space-between;

		padding: 2.4rem;
		border-bottom: 1px solid ${props.theme.bc2}50;
		color: ${props.theme.bc2};
		background: transparent;
		transition: all 0.25s ease-in-out;

		svg {
			fill: ${props.theme.bc2};
		}

		${!props.atTop &&
		css`
			background: ${props.theme.bc2};
			color: ${props.theme.bc1};
			border-bottom: 1px solid ${props.theme.bc1};

			svg {
				fill: ${props.theme.bc1};
			}

			a span {
				border: 1px solid ${props.theme.bc1};
				color: ${props.theme.bc1};

				&:hover {
					border: 1px solid ${props.theme.bc1};
					background: ${props.theme.bc1};
					color: ${props.theme.bc2};
				}
			}
		`}

		${props.noBg &&
		css`
			background: ${props.theme.bc2};
			color: ${props.theme.bc1};
			border-bottom: 1px solid ${props.theme.bc1};

			svg {
				fill: ${props.theme.bc1};
			}

			a span {
				border: 1px solid ${props.theme.bc1};
				color: ${props.theme.bc1};

				&:hover {
					border: 1px solid ${props.theme.bc1};
					background: ${props.theme.bc1};
					color: ${props.theme.bc2};
				}
			}
		`}

		${breakup.large`
            padding: 3.6rem 4.8rem;
        `}
	`
);

export const Info = styled.div`
	position: relative;
`;

export const Desc = styled(P)(
	(props) => css`
		position: absolute;
		left: calc(100% + 4.8rem);
		top: 50%;
		transform: translateY(-50%);
		width: 48rem;
		color: inherit;
	`
);

export const Contact = styled.div(
	(props) => css`
		position: absolute;
		top: 50%;
		right: 1.2rem;
		transform: translateY(-50%);

		${breakup.large` right: 4.8rem; `}
	`
);
